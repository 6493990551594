/* eslint-disable no-template-curly-in-string */
interface AppConfig {
  environmentName: string
  headlineDownloadLink: string
  appStoreBadgeLink: string
}

const localConfig: AppConfig = {
  environmentName: "local",
  headlineDownloadLink: "https://get.onuniverse.com/UnpQ/k89brm41",
  appStoreBadgeLink: "https://get.onuniverse.com/UnpQ/k89brm41",
}

const remoteConfig: AppConfig = {
  environmentName: "production",
  headlineDownloadLink: "https://get.onuniverse.com/UnpQ/k89brm41",
  appStoreBadgeLink: "https://get.onuniverse.com/UnpQ/k89brm41",
}

const config: AppConfig = process.env.REACT_APP_LOCAL === "true" ? localConfig : remoteConfig

export default config
